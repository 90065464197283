<template>
  <div>
    <v-row
      v-if="
        oportunidades && oportunidades.data && oportunidades.data.length > 0
      "
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
        v-for="item in oportunidades.data"
        :key="item.id"
      >
        <v-card height="100%" class="px-4 pt-1">
          <div class="d-flex flex-row align-center justify-start mt-3 mb-5">
            <!-- Logotipo cliente -->
            <v-avatar size="46" color="secondary">
              <img
                v-if="item && item.cliente && item.cliente.logotipo"
                :src="item.cliente.logotipo"
                :alt="
                  item && item.cliente && item.cliente.nome
                    ? `${item.cliente.nome}-logotipo`
                    : 'logotipo'
                "
              />
              <v-icon v-else>mdi-domain</v-icon>
            </v-avatar>
            <!-- Titulo e nome do cliente -->
            <div class="d-flex flex-column">
              <v-card-title
                :class="`${
                  item && item.cliente && item.cliente.nome ? 'pb-4' : 'pb-0'
                } pt-0`"
                style="color: #152d40 !important"
                tag="h2"
              >
                {{ item.titulo }}
              </v-card-title>
              <v-card-subtitle
                v-if="item && item.cliente && item.cliente.nome"
                class="pb-0"
                tag="h3"
              >
                {{ item.cliente.nome }}
              </v-card-subtitle>
            </div>
          </div>
          <v-card-text class="px-2 pb-2">
            <div class="d-flex flex-column">
              <!-- Categoria -->
              <div class="pb-4">
                <p class="pa-0 ma-0">
                  <v-icon left size="20" color="warning">mdi-tag</v-icon>
                  {{ item.categoria }}
                </p>
              </div>

              <div class="d-flex flex-row flex-wrap align-center">
                <!-- Localidade -->
                <v-tooltip
                  v-if="
                    item &&
                    item.endereco &&
                    item.endereco.cidade &&
                    item.endereco.uf
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="ma-0 mx-1 my-1 pa-0">
                      <v-icon>mdi-map-marker</v-icon>
                      {{ item.endereco.cidade }} -
                      {{ item.endereco.uf }}
                    </p>
                  </template>
                  <span>Localidade</span>
                </v-tooltip>
                <!-- Tipo da vaga -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="ma-0 mx-1 my-1 pa-0">
                      <v-icon>mdi-briefcase-variant</v-icon> {{ item.tipo }}
                    </p>
                  </template>
                  <span>Tipo da vaga</span>
                </v-tooltip>
                <!-- Modalidade -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="ma-0 mx-1 my-1 pa-0">
                      <v-icon>mdi-office-building-marker-outline</v-icon>
                      {{ item.local_vaga | capitalize() }} Presencial
                    </p>
                  </template>
                  <span>Modalidade</span>
                </v-tooltip>
                <!-- PcD -->
                <v-tooltip v-if="item.pcd" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <p v-bind="attrs" v-on="on" class="ma-0 mx-1 my-1 pa-0">
                      <v-icon>mdi-wheelchair</v-icon>
                      Disponível para PcD
                    </p>
                  </template>
                  <span>PcD</span>
                </v-tooltip>
              </div>

              <div class="my-2 d-flex flex-row align-center justify-end">
                <v-btn
                  class="mt-4 mt-md-0"
                  small
                  color="accent"
                  @click="redirectVagaDetail(item)"
                >
                  <v-icon class="mr-2">mdi-eye-outline</v-icon> Mais Detalhes
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CardOportunidades",
  computed: {
    oportunidades: {
      get() {
        return this.$store.getters["resCandidatos/getOportunidades"];
      },
    },
  },
  methods: {
    redirectVagaDetail(item) {
      this.$router.push({
        name: "oportunidade-vaga",
        params: { uuid: item.uuid },
      });
    },
  },
};
</script>

<style></style>
