import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.oportunidades && _vm.oportunidades.data && _vm.oportunidades.data.length > 0
    )?_c(VRow,_vm._l((_vm.oportunidades.data),function(item){return _c(VCol,{key:item.id,attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VCard,{staticClass:"px-4 pt-1",attrs:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-start mt-3 mb-5"},[_c(VAvatar,{attrs:{"size":"46","color":"secondary"}},[(item && item.cliente && item.cliente.logotipo)?_c('img',{attrs:{"src":item.cliente.logotipo,"alt":item && item.cliente && item.cliente.nome
                  ? ((item.cliente.nome) + "-logotipo")
                  : 'logotipo'}}):_c(VIcon,[_vm._v("mdi-domain")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c(VCardTitle,{class:((item && item.cliente && item.cliente.nome ? 'pb-4' : 'pb-0') + " pt-0"),staticStyle:{"color":"#152d40 !important"},attrs:{"tag":"h2"}},[_vm._v(" "+_vm._s(item.titulo)+" ")]),(item && item.cliente && item.cliente.nome)?_c(VCardSubtitle,{staticClass:"pb-0",attrs:{"tag":"h3"}},[_vm._v(" "+_vm._s(item.cliente.nome)+" ")]):_vm._e()],1)],1),_c(VCardText,{staticClass:"px-2 pb-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"pb-4"},[_c('p',{staticClass:"pa-0 ma-0"},[_c(VIcon,{attrs:{"left":"","size":"20","color":"warning"}},[_vm._v("mdi-tag")]),_vm._v(" "+_vm._s(item.categoria)+" ")],1)]),_c('div',{staticClass:"d-flex flex-row flex-wrap align-center"},[(
                  item &&
                  item.endereco &&
                  item.endereco.cidade &&
                  item.endereco.uf
                )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 my-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(item.endereco.cidade)+" - "+_vm._s(item.endereco.uf)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Localidade")])]):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 my-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-briefcase-variant")]),_vm._v(" "+_vm._s(item.tipo)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Tipo da vaga")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 my-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-office-building-marker-outline")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(item.local_vaga))+" Presencial ")],1)]}}],null,true)},[_c('span',[_vm._v("Modalidade")])]),(item.pcd)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ma-0 mx-1 my-1 pa-0"},'p',attrs,false),on),[_c(VIcon,[_vm._v("mdi-wheelchair")]),_vm._v(" Disponível para PcD ")],1)]}}],null,true)},[_c('span',[_vm._v("PcD")])]):_vm._e()],1),_c('div',{staticClass:"my-2 d-flex flex-row align-center justify-end"},[_c(VBtn,{staticClass:"mt-4 mt-md-0",attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.redirectVagaDetail(item)}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-eye-outline")]),_vm._v(" Mais Detalhes ")],1)],1)])])],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }